import React, { useEffect, useState } from "react";
import UseList from "../../hooks/use-list";
import File from "../fileInfo/fileInfo";
import Spinner from "../spinner/spinner";
import {Directories, TitleSubjects} from "../../utils/constants";
import {getSubject} from "../../config/config";
import {Files} from "../interfaces";
import {getReadableDate} from "../../utils/utils";
import { makeStyles } from "@mui/styles";
import { ITheme } from "../interfaces";


const useStyles = makeStyles((theme: ITheme) => ({
    mtuSubjectArea: {
        display: 'block',
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        marginInlineStart: '40px',
        marginInlineEnd: '40px'
    },
    subjectContainer: {
        display: 'block',
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        marginInlineStart: '40px',
        marginInlineEnd: '40px'
    },
    subject: {
        fontSize: 'small',
        lineHeight: 1.2
    }
}));

const Subjects = () => {
  const [getObject, files] = UseList();
  const [objects, setObjects] = useState<Files[]>([]);
  const styles = useStyles();

  useEffect(() => {
      getObject(Directories.SUBJECTS);
  });

  useEffect(() => {
    if (!files || files.length === 0) return;
      files && files.sort((a: any, b: any) => getSubject(a.Key).localeCompare(getSubject(b.Key)));
    setObjects(files);
  }, [files]);

  return (
    <div className={styles.mtuSubjectArea}>
      <Spinner isLoading={objects.length === 0} />
      <b>
        Click on one of the subjects below to download All TITLES in that
        SUBJECT:
        <br /> (updated : {objects && getReadableDate(objects[0]?.LastModified.toString())})
      </b>
      <ul className={styles.subjectContainer}>
        {objects &&
          objects.map((file: any) => {
            if (getSubject(file.Key)) {
              return (
                <File
                  key={file.key}
                  fileData={file}
                  link={getSubject(file.Key)}
                  hideDate={true}
                  className={styles.subject}
                />
              );
            } else {
                console.error(`Undefined subject: ${file.Key}`)
            }
          })}
      </ul>
    </div>
  );
};

export default Subjects;

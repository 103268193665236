import "./App.css";
import ComponentRoutes from "./routes/componentRoutes";
import { ToastProvider} from 'react-toast-notifications';


function App() {
  return (
        <ToastProvider  autoDismiss={true} >
      <div className="App">
          <ComponentRoutes/>
      </div>
        </ToastProvider>
  );
}

export default App;

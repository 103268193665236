import React, {SyntheticEvent, useEffect, useState} from 'react';
import {useNavigate , useLocation} from "react-router-dom";
import image from '../../no_image.jpg'
import {Directories} from "../../utils/constants";
import {Box, Button, Card,CardMedia, Grid, CardActions} from "@mui/material";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {makeStyles} from "@mui/styles";
import CardHeader from '@mui/material/CardHeader';
import {ITheme} from "../interfaces";
import {useToasts} from "react-toast-notifications";
import {convertISBN10ToISBN13} from "../../utils/utils";

const useStyles = makeStyles((theme:ITheme) => ({
    cardIsbn: {
        textAlign: 'center', m: 1,fontSize: 20, fontWeight: 'bold',
    },
    media: {
        height: 300,
        width: 300,
    },
}));

const CoverImagePreview = () => {
    const location = useLocation();
    const classes = useStyles();
    const navigate = useNavigate();
    const [isbn, setIsbns] = useState<string[]>([]);
    const [noCoverPresent, setNoCoverPresent] = useState(true);
    const { addToast } = useToasts();

    useEffect(() => {
        if (location?.state?.detail) {
            setIsbns(location.state.detail);
        } else {
            navigate("/");
        }
    }, [location]);


    function imageUrlGenerator(isbn:any) {
        if(isbn.length == 10){
            isbn = convertISBN10ToISBN13(isbn);
        }
        return Directories.IMAGE + isbn + '.jpg';
    }

    const onDownloadCovers = () => {
        isbn.filter((isbn:any) => {
            if (!noCoverPresent) {
                let element = document.createElement("a");
                element.href = imageUrlGenerator(isbn);
                element.download = `${isbn}.jpg`;
                element.click();
            }
        })
    }

    function isImagePresent(e:any){
        let loadedImage=e?.target?.src;
        if(loadedImage && loadedImage.includes("no_image")){
            setNoCoverPresent(true);
        }
        else {
            setNoCoverPresent(false);
        }
    }

    return (
        <>
            {isbn && isbn.length>0 &&
            isbn?.map((isbnValue:string) => (
            <Grid container  alignItems={"center"}   direction="column">
            <Card >
                <CardMedia className={classes.media}
                    component="img"
                    alt="Cover Image"
                    image={imageUrlGenerator(isbnValue)}
                           onLoad={(e:SyntheticEvent<HTMLDivElement>)=>isImagePresent(e)}
                           onError={({currentTarget }) => {
                               currentTarget.onerror = null;
                               currentTarget.src = image;
                               addToast(`0 Matching Images for ISBN ${isbnValue}`,{ appearance: 'error' })
                           }}
                />
                <CardHeader
                    style={{padding:'5px'}}
                    subheader={isbnValue}
                    align={'center'}
                />
                <CardActions>
                    <Button fullWidth variant="contained" size="medium"  onClick={onDownloadCovers} disabled={noCoverPresent}>
                        Download <FileDownloadOutlinedIcon />
                    </Button>
                </CardActions>
                </Card>

            </Grid>

            ))
            }

        </>
    );
}

export default CoverImagePreview;

import React, { useEffect, useState } from "react";
import UseList from "../../hooks/use-list";
import Subjects from "./subjects";
import Spinner from "../spinner/spinner";
import { Directories } from "../../utils/constants";
import { makeStyles } from "@mui/styles";
import {Files, ITheme} from "../interfaces";
import {getMtu, getMtuIndex} from "../../config/config";
import File from "../fileInfo/fileInfo";

const useStyles = makeStyles((theme: ITheme) => ({
  title: {
    textAlign: "initial",
    fontFamily: "calibri, Helvetica",
    fontWeight: "bold",
    color: "#009090",
    backgroundColor:"#CCCCCC",
    width: "80%",
    display: "block",
    padding: "1px 2px"
  }
}));

const MonthlyTitleUpdate = (props: any) => {
  const [getObject, files] = UseList();
  const [objects, setObjects] = useState<Files[]>([]);
  const classes = useStyles();

  useEffect(() => {
      getObject(Directories.MTU);
  });

  useEffect(() => {
    if (!files) return;
      setObjects(files);
  }, [files]);

  return (
    <div>
      <span className={classes.title}>Monthly Title Update</span>
      <Spinner isLoading={objects.length === 0} />
      <ul>
        {objects &&
          objects
            .filter((obj: any) => getMtu(obj.Key))
            .filter((obj: any, i: number) => i <= 3)
            .sort((a: any, b: any) => getMtuIndex(a.Key).localeCompare(getMtuIndex(b.Key)))
            .map((file: Files) => {
                  return (
                  <File
                    key={file.Key}
                    fileData={file}
                    name={getMtu(file.Key)}
                  />
                );
            })}
      </ul>
      <Subjects/>
    </div>
  );
};

export default MonthlyTitleUpdate;

import {Directories} from "./constants";
import {IFileMeta} from "../components/interfaces";

export function getReadableDate(date: string){
    const options: object = {year: 'numeric', month: 'long', day: 'numeric'}
    const EN_US = "en-US"
    return new Date(date).toLocaleDateString(EN_US, options)
}

export function bytesToSize(bytes: number) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    let i: number = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[i];
}

export function getOnixFolder({version, period, type, region}: IFileMeta): any {
    let path: string = "";
    let freq;
    switch(period){
        case "WF": 
        case "W": 
          freq = "WEEKLY_" 
          break;
        case "M": 
          freq = "MONTHLY_"
          break
        case "D": 
          freq = "DAILY_"
          break
        default:
          freq = ""
      }
    path += version === "2.1" ? "ONIX2_" : `ONIX3_${region}_`;
    path += freq;
    path +=
        type === "O"
            ? "OBOOK"
            : type === "P"
                ? "PBOOK"
                : type === "E"
                    ? "EBOOK"
                    : type === "eMRW"
                        ? "EMRW"
                        : "LICENSE";
    return Directories[path] || "";
}

export function convertISBN10ToISBN13(isbn10: string) {
    if (!validISBN(isbn10)) {
        return null;
    }
    const fragment = "978" + isbn10.substring(0, 9);
    return fragment + generateCheckDigit(fragment);
}

export function validISBN(isbn: string): boolean {
    if (isbn.length !== 13 && isbn.length !== 10) {
        return false;
    }
    if (isbn.length === 13) {
        for (let i = 0; i < 13; i++) {
            if (!isCharNumber(isbn.charAt(i))) {
                return false;
            }
        }
        return isbn.substring(12) === generateCheckDigit(isbn.substring(0, 12));
    }
    for (let i = 0; i < 9; i++) {
        if (!isCharNumber(isbn.charAt(i))) {
            return false;
        }
    }
    if (!isCharNumber(isbn.charAt(9)) && isbn.charAt(9) !== "X") {
        return false;
    }
    return isbn.substring(9) === generateCheckDigit(isbn.substring(0, 9));
}

export function generateCheckDigit(fragment: string):string | null {
    if (fragment == null) {
        return null;
    }
    if (fragment.length === 12) {
        let sum = 0;
        for (let i = 0; i < 12; i++) {
            let c = fragment.charAt(i);
            if (!isCharNumber(c)) {
                return null;
            }
            if (i % 2 === 0) {
                sum += +c;
            } else {
                sum += +c * 3;
            }
        }
        let remainder = sum % 10;
        if (remainder === 0) {
            return "0";
        }
        return (10 - remainder).toString();
    }
    if (fragment.length === 9) {
        let sum = 0;
        for (let i = 1; i <= 9; i++) {
            let c = fragment.charAt(i - 1);
            if (!isCharNumber(c)) {
                return null;
            }
            sum += i * (+c);
        }
        let remainder = sum % 11;
        if (remainder === 10) {
            return "X";
        }
        return remainder.toString();
    }
    return null;
}

function isCharNumber(c:string) {
    return c >= '0' && c <= '9';
}

export function podYear(){
    return  new Date().getFullYear() % 100;
}


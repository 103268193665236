import {MTUFiles, MTUFileOrder, TitleSubjects} from "../utils/constants"

export function getSubject(key: string) {
    let subject:string = "";
    Object.keys(TitleSubjects).forEach(x => {
        if (key.includes(x)) {
            subject = TitleSubjects[x];
        }
    })
    return subject;
}

export function getMtu(key: string) {
    let mtu: string = "";
    Object.keys(MTUFiles).forEach(x => {
        if (key.includes(x)) {
            mtu = MTUFiles[x];
        }
    })
    if (!mtu && key?.includes("wiley")) {
        mtu = MTUFiles.other;
    }
    return mtu;
}

export function getMtuIndex(key: string) {
    let index: string = "";
    Object.keys(MTUFileOrder).forEach(x => {
        if (key.includes(x)) {
            index = MTUFileOrder[x];
        }
    })
    if (!index && key) {
        index = MTUFileOrder.other;
    }
    return index;
}

export function getSubjectKey(key: string) {
    key = key.split('/')[2];

    let subjectKey=key.replace('sub','').replaceAll(/[0-9]/g, '');
    console.log(subjectKey)
    return subjectKey;
}

import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import HomePage from "../components/home/homePage";
import CoverImagePreview from "../components/nonOnix/coverImagePreview";
import CatalogImageHeader from "../components/home/header";


const ComponentRoutes = () => {
    return (
        <>
            <CatalogImageHeader/>
            <BrowserRouter>
                <Routes>
                    <Route path="/"  element={<HomePage/>}/>
                    <Route path="/covers" element={<CoverImagePreview/>}/>
                </Routes>
            </BrowserRouter >
        </>
    );
};

export default ComponentRoutes;

import React, { useEffect, useState } from "react";
import { getOnixFolder } from "../../utils/utils";
import File from "../fileInfo/fileInfo";
import useList from "../../hooks/use-list";
import {Files, IFileMeta} from "../interfaces";
import Spinner from "../spinner/spinner";

export const Title = ( version:any, period:any, type:any, region: any, i: number ): any => {
  var suffix;
  switch(period){
    case "WF": 
      suffix = `Weekly Full ${++i}` 
      break;
    case "W": 
      suffix = "Weekly Change" 
      break;
    case "M": 
      suffix = "Monthly Full"
      break
    case "D": 
      suffix = "Daily Change"
      break
    default:
      suffix = ""
  }
  const GO = `${region} Global ONIX`;
  return type === "L"
    ? [`${isGlobalOnix(version)?GO:`ONIX ${version}`} License - `, <b>{suffix}</b>]
    : type === "eMRW" ? [
        <strong>{type}</strong>,
        ` ${isGlobalOnix(version)?GO:`ONIX ${version}`} - `,
        <strong>{suffix}</strong>,
      ]:[
        <strong>{type}-Books</strong>,
        ` ${isGlobalOnix(version)?GO:`ONIX ${version}`} - `,
        <strong>{suffix}</strong>,
      ];
};

const isGlobalOnix = (version:any) => {
    return version == '3.0'
}

const FileList = ({ version, period, type, region }: IFileMeta) => {
  const [getObject, files] = useList();

  const [objects, setObjects] = useState<Files[]>([]);
  let length = period === "W" ? 4 : 1;
  switch(period){
    case "WF":
    case "W": 
      if (region == "AUS"){
        length = 3
      }
      else length = 4;
      break;
    case "M": 
      length = 1;
      break;
    case "D":
      if(region == "UK"){
        length = 7
      } 
      else if(region = "AUS"){
        length = 2
      }
      else length = 1;
      break
  }

  useEffect(() => {
      getObject(getOnixFolder({ version, period, type, region }));
  }, [version, period, type, region]);

  useEffect(() => {
    if (!files) return;
      setObjects(files);
  }, [files]);

  return (
    <>
      <Spinner
        isLoading={objects.length === 0}
        key={`${version}-${period}-${type}`}
      />
      {objects &&
        objects
          .filter((obj: any, i: number) => i < length)
          .map((file: Files, i : number) => (
            <File
              name={Title(version, period, type, region, i)}
              fileData={file}
              key={`${version}-${period}-${type}`}
            />
          ))}
    </>
  );
};

export default FileList;

import {useEffect, useState} from 'react';
import {Files} from "../components/interfaces";
import {Directories} from "../utils/constants";

const UseList = () => {
    const [key, setKey] = useState('');
    const [listFiles, setListFiles] = useState<Files[]>([]);


    useEffect(() => {
        if (!key) return;
        let dataList = localStorage.getItem('listData');
        let contentList=null;
        if (dataList != null) {
             contentList = JSON.parse(dataList);
        }

        if(!contentList){
            setInterval(()=>{
                dataList=localStorage.getItem('listData');
                if (dataList != null) {
                    contentList = JSON.parse(dataList);
                }
            },5000)
        }
        else {
            let listOfContent = contentList[key]
            if (listOfContent && listOfContent?.length > 0) {
                listOfContent = JSON.parse(listOfContent);
                if (listOfContent && listOfContent?.length > 1) {
                    if(key===Directories.SUBJECTS){
                        listOfContent.sort((a:Files, b:Files) => a.Key.toLowerCase().localeCompare( b.Key.toLowerCase()));
                        setListFiles(listOfContent);
                    }
                    else {
                        listOfContent.sort((a:Files, b:Files) => +new Date(b.LastModified) - +new Date(a.LastModified));
                    }
                }
               setListFiles(listOfContent);
            }
        }

    }, [key])


    const getObjects = (key:string):void => {
        setKey(key);
    }

    return [getObjects, listFiles] as const;
};

export default UseList;

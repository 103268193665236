export const Directories: any = {

    ONIX2_MONTHLY_PBOOK: 'ONIX2_MONTHLY_PBOOK',
    ONIX2_MONTHLY_EBOOK: 'ONIX2_MONTHLY_EBOOK',
    ONIX2_MONTHLY_OBOOK: 'ONIX2_MONTHLY_OBOOK',
    ONIX2_MONTHLY_LICENSE: 'ONIX2_MONTHLY_LICENSE',

    //Onix 2.1 Weekly
    ONIX2_WEEKLY_PBOOK: 'ONIX2_WEEKLY_PBOOK',
    ONIX2_WEEKLY_EBOOK: 'ONIX2_WEEKLY_EBOOK',
    ONIX2_WEEKLY_OBOOK: 'ONIX2_WEEKLY_OBOOK',
    ONIX2_WEEKLY_LICENSE: 'ONIX2_WEEKLY_LICENSE',


    ONIX3_CAN_MONTHLY_PBOOK: 'ONIX3_CAN_MONTHLY_PBOOK',

    ONIX3_SIN_MONTHLY_PBOOK: 'ONIX3_SIN_MONTHLY_PBOOK',

    ONIX3_UK_MONTHLY_PBOOK: 'ONIX3_UK_MONTHLY_PBOOK',

    ONIX3_US_MONTHLY_PBOOK: 'ONIX3_US_MONTHLY_PBOOK',
    ONIX3_US_MONTHLY_EBOOK: 'ONIX3_US_MONTHLY_EBOOK',
    ONIX3_US_MONTHLY_OBOOK: 'ONIX3_US_MONTHLY_OBOOK',
    ONIX3_US_MONTHLY_EMRW: 'ONIX3_US_MONTHLY_EMRW',
    ONIX3_US_MONTHLY_LICENSE: 'ONIX3_US_MONTHLY_LICENSE',


    ONIX3_AUS_WEEKLY_PBOOK: 'ONIX3_AUS_WEEKLY_PBOOK',

    ONIX3_CAN_WEEKLY_PBOOK: 'ONIX3_CAN_WEEKLY_PBOOK',

    ONIX3_SIN_WEEKLY_PBOOK: 'ONIX3_SIN_WEEKLY_PBOOK',

    ONIX3_US_WEEKLY_PBOOK: 'ONIX3_US_WEEKLY_PBOOK',
    ONIX3_US_WEEKLY_EBOOK: 'ONIX3_US_WEEKLY_EBOOK',
    ONIX3_US_WEEKLY_OBOOK: 'ONIX3_US_WEEKLY_OBOOK',
    ONIX3_US_WEEKLY_EMRW: 'ONIX3_US_WEEKLY_EMRW',
    ONIX3_US_WEEKLY_LICENSE: 'ONIX3_US_WEEKLY_LICENSE',

    ONIX3_AUS_DAILY_PBOOK: 'ONIX3_AUS_DAILY_PBOOK',
    ONIX3_UK_DAILY_PBOOK: 'ONIX3_UK_DAILY_PBOOK',

    //Nonix files
    MTU: 'MTU',
    POD: 'POD',
    WEEKLY_COVERIMAGE: 'WEEKLY_COVERIMAGE',
    MISCELLANEOUS: 'MISCELLANEOUS',
    SUBJECTS:'SUBJECTS',
    IMAGE : '/images/db/jimages/',
    SUB_DOMAIN : 'coltrane-catalog-image-site',
};

export const TitleSubjects: any = {
    accounting : 'Accounting',
    anthropologyarchaeologygeneral : 'Anthropology & Archaeology',
    architecture : 'Architecture',
    anthropology : 'Anthropology',
    archaeology : 'Archaeology',
    artappliedarts : 'Arts',
    agriculture : 'Agriculture',
    biomedicalengineering : 'Biomedical Engineering',
    businessmanagement : 'Business',
    chemicalengineering : 'Chemical Engineering',
    chemistry : 'Chemistry',
    childrens : 'Children\'s',
    civilengineeringconstruction : 'Civil Engineering',
    classicalstudies : 'Classical Studies',
    generalcommunicationmediastudies : 'Communication & Media',
    computerscience : 'Computer Science',
    culturalstudiesgeneral : 'Cultural Studies',
    generaldentistry : 'Dentistry',
    developmentstudies : 'Development Studies',
    earthscience : 'Earth Science',
    economics : 'Economics',
    education : 'Education',
    electricalelectronicsengineering : 'Electrical & Electronics Engineering',
    generalendusercomputing : 'End-User Computing',
    environmentalstudies : 'Environmental Studies',
    generalequine : 'Equine',
    evaluationresearchmethods : 'Evaluation & Research Methods',
    familystudiesgeneral : 'Family Studies',
    financeinvestments : 'Finance & Investments',
    generalaquaculturefisheriesfishscience : 'Fisheries & Fish Science',
    foodsciencetechnology : 'Food Science & Technology',
    hospitality : 'Hospitality',
    industrialengineering : 'Industrial Engineering',
    linguistics : 'Language & Linguistics',
    law : 'Law',
    lifesciences : 'Life Sciences',
    lifestylesgeneral : 'Lifestyles',
    generalliterature : 'Literature',
    materialsscience : 'Materials Science',
    mathematics : 'Mathematics',
    mechanicalengineering : 'Mechanical Engineering',
    medicalscience : 'Medical Sciences',
    nanotechnologygeneral : 'Nanotechnology',
    nursinggeneral : 'Nursing',
    generalphilosophy : 'Philosophy',
    physics : 'Physics',
    politicalscience : 'Political Science',
    polymersciencetechnologygeneral : 'Polymer Science & Technology',
    psychologygeneral : 'Psychology',
    referencegeneral : 'Reference',
    religiontheology : 'Religion',
    securitymanagement : 'Security Management',
    generalselfhelp : 'Self-Help',
    socialpolicywelfare : 'Social Policy & Welfare',
    generalsociology : 'Sociology',
    statistics : 'Statistics',
    testprepgeneral : 'Test Prep',
    travelgeneral : 'Travel',
    urbanstudies : 'Urban Studies',
    veterinarymedicine : 'Veterinary Medicine',
    foreignlanguages : 'Foreign Languages',
    generalandintroductoryagriculture : 'Agriculture',
    generalarts : 'Arts',
    geography : 'Geography',
    graphicdesign : 'Graphic Design',
    history : 'History',
    introgeneralbiomedicalengineering : 'Biomedical Engineering',
    languageandlinguisticsgeneral : 'Language & Linguistics',
    literaturegeneral : 'Literature',
    medicalsciences : 'Medical Sciences',
    nursing : 'Nursing',
    politicalsciencegeneral : 'Political Science',
    psychology : 'Psychology',
    religion : 'Religion',
    socialscience : 'Social Sciences & Liberal Arts',
    sociologygeneral : 'Sociology',
    veterinarymedicinegeneral : 'Veterinary Medicine'
};

export const MTUFiles: any = {
    wileyTOC: 'Abbreviated Monthly Title Update',
    wileyebooks: 'E-books',
    wileyPOD: 'Print-on-Demand Titles',
    other: 'Full Monthly Title Update',
}

export const MTUFileOrder: any = {
    other: "1",
    wileyTOC: "2",
    wileyebooks: "3",
    wileyPOD: "4",
}

export const PERIODS: any = {
    WF: "weekly",
    W: "weekly",
    D: "daily",
    M: "monthly"
}

export const REGIONS: any = {
    AUSTRALIA: "AUS",
    CANADA: "CAN",
    SINGAPORE: "SIN",
    US: "US",
    UK: "UK"
}

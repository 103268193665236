import React from "react";
import { bytesToSize, getReadableDate } from "../../utils/utils";
import { IFileInfo } from "../interfaces";

const File = ({ key, name, fileData, link, hideDate, className }: IFileInfo) => {
  return (
    <>
      <li key={key} className={className}>
        <a className={className} key={key} href={`${fileData?.Key}`}>
          {link ? link : "Download"}
        </a>{" "}
        {name}{" "}
        {!hideDate &&
          ` (Updated: ${getReadableDate(fileData?.LastModified)} Size: ${bytesToSize(
            fileData?.Size
          )})`}
      </li>
    </>
  );
};

export default File;

import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {validISBN} from "../../utils/utils";

const ImageSearch = () => {
    const { addToast } = useToasts();
    const navigate = useNavigate();
    const [error, setError] = useState('');

    const handleSubmit = (e: any) => {
        e.preventDefault();
        let isbnValues = e.target?.isbns?.value;
        if (!isbnValues) {
            setError("Not valid input")
        } else {
            let isbnList = isbnValues.split("\n");
            let validIsbns = validIsbnList(isbnList)
            if (validIsbns && validIsbns.length > 0) {
                navigate(
                    '/covers',
                    {state: {detail: validIsbns}}
                )
            }
        }
    }

    function validIsbnList(isbnList: []) {
        let vIsbnList: any = [];
        if (!isbnList) return false;
        let isValidISBN: boolean = false;
        isbnList.forEach((val: string) => {
            isValidISBN = validISBN(val);
            if (isValidISBN) {
                if (val.length === 13) {
                    vIsbnList.push(val);
                } else if (val.length === 10) {
                    vIsbnList.push(val);
                }
            } else {
                addToast(`0 Matching Images for ISBN ${val}`,{ appearance: 'error' })
            }
        })
        return vIsbnList;
    }

    return (
        <div>
            <hr/>
            <form onSubmit={handleSubmit}>
                <label>Search by ISBN : &nbsp;</label>
                <input type="text" name="isbns"/>
                <input type="submit" style={{marginLeft: '5px'}} value="Search"/>
            </form>
        </div>
    );
};

export default ImageSearch;

import React, { useEffect, useState } from "react";
import UseList from "../../hooks/use-list";
import File from "../fileInfo/fileInfo";
import Spinner from "../spinner/spinner";
import { Directories } from "../../utils/constants";
import { makeStyles } from "@mui/styles";
import { ITheme } from "../interfaces";
import {podYear} from "../../utils/utils";

const useStyles = makeStyles((theme: ITheme) => ({
  title: {
    textAlign: "initial",
    fontFamily: "calibri, Helvetica",
    fontWeight: "bold",
    color: "#009090",
    backgroundColor:"#CCCCCC",
    width: "80%",
    display: "block",
    padding: "1px 2px"
  },
}));
const Miscellaneous = () => {
  const [getObject, files] = UseList();
  const [objects, setObjects] = useState<any>([]);
  const classes = useStyles();

  useEffect(() => {
      getObject(Directories.MISCELLANEOUS);
  });

  useEffect(() => {
    if (!files) return;
    setObjects(files);
  }, [files]);

  let getPODYear = (file: any) => {
    return file.Key.split('_').pop().split('.')[0]
  }

  return (
    <div>
      <Spinner isLoading={objects.length === 0} />
      <span className={classes.title}>Miscellaneous Download Area</span>
      <ul>
        {objects &&
          objects
            .sort((a: any, b: any) => a.Key.localeCompare(b.Key))
            .filter((obj: any, i: number) => i < 3)
            .map((file: any) => {
              if (file.Key.includes("wiley_OOP_monthly")) {
                return (
                    <File
                        key={file.Key}
                        fileData={file}
                        name={ `Out-of-Print (OOP) ${getPODYear(file)}`}
                    />
                );
              } else if (file.Key.includes("wiley_OOP")) {
                return (
                    <File
                        key={file.Key}
                        fileData={file}
                        name={"Out-of-Print (OOP)"}
                    />
                );
              } else if (file.Key.includes("wileyPOD")) {
                return (
                    <File
                        key={file.Key}
                        fileData={file}
                        name={"Wiley Print-On-Demand"}
                    />
                );
              }
            })}
      </ul>
      <br />
    </div>
  );
};

export default Miscellaneous;

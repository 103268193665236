import MonthlyTitleUpdate from '../nonOnix/monthlyTitleUpdate';
import Miscellaneous from '../nonOnix/miscellaneous';
import WeeklyCoverImage from '../nonOnix/weeklyCoverImage';
import OnixFile from '../onix/onix';
import { makeStyles } from '@mui/styles';
import {Typography} from '@mui/material';
import {ITheme} from '../interfaces';
import UseListAllData from "../../hooks/Use-load-initial-data";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import {useEffect} from "react";
import { REGIONS } from '../../utils/constants';
const useStyles = makeStyles((theme:ITheme) => ({
    wrapper: {
        display: 'flex',
        // flexDirection: 'column',
        width: '100%',
        height: '100%',
        backgroundColor :'#EEEEFF',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        marginTop: 0,
    },
    highlightedContent: {
        color: '#FF0000',
        fontFamily : "Times New Roman !important",
        marginLeft:'10px !important',
        fontSize: 'small !important',
        lineHeight: '1 !important'
    }
}));

function HomePage(props: any) {
    const classes = useStyles();
    const [isLoading, getObjectsAll] = UseListAllData();
    useEffect(()=>{
        getObjectsAll()
    },[]);

    if(!isLoading){
        return(
            <>
                <Typography variant={'body1'} className={classes.highlightedContent}>
                    **Any questions concerning the Monthly Title Update and Image Download
                    Site contact:
                    <br />
                    Frank McDermott <br />
                    (201) 748-6185 <br />
                    email: fmcdermo@wiley.com
                </Typography>

                <blockquote>
                    <MonthlyTitleUpdate />
                    <OnixFile version={'2.1'} region={REGIONS.US} />
                    <OnixFile version={'3.0'} label={'Australia Global ONIX'} region={REGIONS.AUSTRALIA}/>
                    <OnixFile version={'3.0'} label={'Canada Global ONIX'} region={REGIONS.CANADA}/>
                    <OnixFile version={'3.0'} label={'Singapore Global ONIX'} region={REGIONS.SINGAPORE}/>
                    <OnixFile version={'3.0'} label={'United Kingdom Global ONIX'} region={REGIONS.UK}/>
                    <OnixFile version={'3.0'} label={'United States Global ONIX'} region={REGIONS.US}/>
                    <blockquote>
                        <Miscellaneous />
                        <WeeklyCoverImage />
                    </blockquote>
                </blockquote>
            </>

        )
    } else{
        return (
            <Box sx={{ width: '100%'}}>
                <LinearProgress />
            </Box>
        )
    }
}

export default HomePage;

import {useEffect, useState} from 'react';
import axios from "axios";
import { useToasts } from 'react-toast-notifications';

const UseListAllData = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { addToast } = useToasts();
    useEffect(() => {
        if (!isLoading) return;
        let get_all_objects: any = "/contents";
        axios.get(get_all_objects)
            .then(function (res: any) {
                localStorage.setItem('listData', JSON.stringify(res.data));
                window.dispatchEvent(new Event("listData"));
                addToast('Successfully loaded the catalog image contents', { appearance: 'success' });
            })
            .catch(function (error: any) {
                if(error?.message){
                    addToast(error?.message, { appearance: 'error' });
                }
                if(localStorage.getItem('listData')) localStorage.removeItem('listData');
            })
            .finally(() => {
                setIsLoading(false);
            });

    }, [isLoading])

    const getObjectsAll = () => {
        setIsLoading(true);
    }


    return [isLoading, getObjectsAll] as const;
};

export default UseListAllData;

import React, { useEffect, useState } from "react";
import File from "../fileInfo/fileInfo";
import Spinner from "../spinner/spinner";
import { Directories } from "../../utils/constants";
import {makeStyles} from "@mui/styles";
import {Files, ITheme} from "../interfaces";
import UseList from "../../hooks/use-list";
import ImageSearch from "./imageSearch";
import {Typography} from "@mui/material";

const useStyles = makeStyles((theme: ITheme) => ({
    title: {
        textAlign: "initial",
        fontFamily: "calibri, Helvetica",
        fontWeight: "bold",
        color: "#009090",
        backgroundColor:"#CCCCCC",
        width: "80%",
        display: "block",
        padding: "1px 2px"
    },
    highlightedContent: {
        color: '#FF0000',
    }

}));

const WeeklyCoverImage = () => {
  const [getObject, files] = UseList();
  const [objects, setObjects] = useState<Files[]>([]);
  const classes = useStyles();

  useEffect(() => {
      getObject(Directories.WEEKLY_COVERIMAGE)
  });

  useEffect(() => {
    if (!files) return;
    setObjects(files);
  }, [files]);

  return (
    <>
      <Spinner isLoading={objects.length === 0} />
      <span className={classes.title}>Image Download Area</span>
      <ImageSearch/>
      <ul>
        {objects &&
        objects
            .filter((obj: any, i: number) => i < 1)
            .map((file: any) => (
                <File
                    key={file.Key}
                    name={"  All NEW images in a zip file"}
                    fileData={file}
                />)
            )}
      </ul>
    </>
  );
};

export default WeeklyCoverImage;

import React, { useEffect, useState } from "react";
import { getOnixFolder } from "../../utils/utils";
import File from "../fileInfo/fileInfo";
import useList from "../../hooks/use-list";
import { IRegionalConfig } from "../interfaces";
import { Title } from "./fileList";
import { PERIODS } from "../../utils/constants";
import Spinner from "../spinner/spinner";

interface Props {
  regionalConfig: IRegionalConfig;
}

const RegionalFiles = ({regionalConfig}: Props) => {

  const [getDailyPbookFiles, dailyPbookFiles] = useList();
  const [getWeeklyPbookFiles, weeklyPbookFiles] = useList();
  const [getMonthlyPbookFiles, monthlyPbookFiles] = useList();
  const [region, setRegion] = useState<string>(regionalConfig.region);
  const [version, setVersion] = useState<string>(regionalConfig.version);

  useEffect(() => {
    setRegion(regionalConfig.region);
    setVersion(regionalConfig.version);
    regionalConfig.periods.map(p => {
      regionalConfig.mediums.map(medium => {
        let type = medium;
        let period= Object.keys(PERIODS).find(key => PERIODS[key] === p) || '';
        let path = getOnixFolder({ version, period, type, region });
        
        if(path){
          PERIODS.W == p && getWeeklyPbookFiles(path);
          PERIODS.D == p && getDailyPbookFiles(path);
          PERIODS.M == p && getMonthlyPbookFiles(path);
        }
      });
    });
  }, [regionalConfig]);

  const getFileSet = (period: string) => {
    if(period == PERIODS.D){
      return dailyPbookFiles;
    }
    else if(period ==PERIODS.W || period ==PERIODS.WF){
      return weeklyPbookFiles;
    }
    else if(period == PERIODS.M){
      return monthlyPbookFiles;
    }
    else return dailyPbookFiles;
  }

  const render = () => {
    const elements: any = [];
    for (let i = 1; i <= regionalConfig.sets; i++) {
      regionalConfig.periods.map(period => {
          regionalConfig.mediums.map((medium: string) => {
            let isLoading = getFileSet(period).length === 0
            const type = medium;
            for(let j=1; j <= regionalConfig[period]['count']; j++){
              let shortPeriod = Object.keys(PERIODS).find(key => PERIODS[key] === period) || '';
              let fileIndex = ((i-1)*regionalConfig[period]['count']+j);
              !isLoading && elements.push(
                <File
                name={Title(version, shortPeriod, type, region, i-1)}
                fileData={getFileSet(period)[fileIndex-1]}
                key={`${version}-${shortPeriod}-${type}`} />
              )
            }
          });
        })
      }

    return elements;
  }

  return(
    <>
    {render()}
    </>
  );

};


export default RegionalFiles;
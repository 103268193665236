import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { BeatLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #9b9b9b;
`;

const Spinner = ({ isLoading = false, key }: any) => {
  let [loading, setLoading] = useState<boolean>(false);
  let [color, setColor] = useState<string>("#9B9B9B");

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  return (
    <div key={key} className="sweet-loading">
      <BeatLoader
        key={key}
        color={color}
        loading={loading}
        css={override}
        size={50}
      />
    </div>
  );
};

export default Spinner;

import React from 'react';
import {Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {ITheme} from "../interfaces";
const useStyles = makeStyles((theme:ITheme) => ({
    title: {
        textAlign: 'center',
         fontSize:'16',
        color :"#009090"
    }
}));
const CatalogImageHeader = () => {
    const classes = useStyles();
    return (
        <div>
            <title>Wiley Monthly Title Update and Image Download Site</title>
            <blockquote className={classes.title }>
                <Typography className={classes.title} variant={"body1"}><b>Wiley Monthly Title Update and Image Download Site</b></Typography>
                <hr/>
            </blockquote>
        </div>
    );
};

export default CatalogImageHeader;
